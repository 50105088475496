/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    outline: none;
    right:0;
    z-index: 3;
    

    &:before {
        font-family: $icon-font;
        font-size: 18px;
        line-height: 1;
        color:$light;
        @include transition;
    }

    &:hover{
        &:before{
            color:rgba($secondary,.4);
        }
    }

    @include breakpoint(small) {
       right:0;
    }
    @include breakpoint(medium) {
       height: 30px;
        width: 30px;

        &:before{
           font-size: 30px; 
        }
    }
    @include breakpoint(huge) {
       height: 40px;
        width: 40px;
        right:0;
        &:before{
           font-size: 40px; 
        }
    }
   
}
.slick-prev {
    left:0;
    right:auto;
    &:before {
        content: '\f053';
    }

     @include breakpoint(small) {
       left:0;
    }
    @include breakpoint(huge) {
        left:0;
        
    }
     
}

.slick-next {
    &:before {
        content: '\f054';
    }
}

.slick-dots {
    position:absolute;
    bottom:rem(20px);
    text-align: center;
    width:100%;
    right:50%;
    transform:translateX(50%);

    @include breakpoint(small) {
         right:rem($base-gap);
        transform:translateX(0);
        width:auto;
        bottom:rem(40px);
    }
    @include breakpoint(giant) {
         bottom:rem(80px);
    }
    @include breakpoint(huge) {
        width:rem(86px);
        left:0;
        right:0;
        margin:auto;
        transform:translateX(690px);
    }

    li {
        display:inline;
        margin-left:rem(10px);
       padding:0;
        &:before{
            content:none;
        }
        &:first-child {
            margin-left:0;
        }

        &.slick-active {
            button{
                 background:$light;
            }
        }
    }

    button{
        width:rem(17px);
        height:rem(15px);
        text-indent: 200%;
        overflow: hidden;
        background:none;
        background-size:100%;
        border:1px solid $light;
        cursor: pointer;
        transition:400ms ease-in-out;

        @include breakpoint(giant) {
           width:rem(22px);
            height:rem(21px);
        }
         &:focus,
         &:hover {
           background:$light;
            outline: none;
        }
    }
}

