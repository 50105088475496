// Keyframe
//////////////////////////////
@keyframes fadeDown {
  from {
    top:-200px;
    opacity:0;
  }

  to {
     top:0;
     opacity:1;
  }
}

// Mobile Menu Bar
//////////////////////////////

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

    background:$light; 
    width:rem(480px); 
    height:100vh; 
    position:fixed; 
    right:0; 
    top:0; 
    z-index:100; 
    margin-top:-100%; 
    transform: translateY(-100%); 
    transition:  margin-top 400ms ease-in-out, 
    transform 400ms ease-in-out, 
    background 400ms ease-in-out; 
    overflow:auto; 

    @include breakpoint(tiny) {
    	width: auto; 
    	max-width: rem(320px);
    }

	// Desktop Navigation
	//////////////////////////////

	@include breakpoint(large) {
		background:none; 
		max-width:none; 
		height: auto; 
		position: absolute; 
		right: rem($base-gap); 
		top: 0; 
		overflow: visible; 
		margin-top: 0; 
		transform: translateY(0);
		transition:background 400ms ease-in-out;
		-webkit-backface-visibility: hidden;
		display:flex;
		flex-direction:column;
		align-items:flex-end;
		width:calc(75% - 36px);


		&.is_stuck {
			background: $primary; 
			width: 100%!important; 
			left: 0;
			animation:fadeDown 1200ms ease-in-out; 
			position:fixed;
			top:0;
			padding:rem(20px) rem($base-gap);

			.contact{
				max-width: rem(850px);
			}
		}

		nav{
			width:100%;
			max-width: rem(705px); 
		}
	}

	@include breakpoint(giant) {
		width:calc(66.666% - 36px);
	}
	@include breakpoint(huge) {
		width:calc(58.333% - 36px);
		&.is_stuck{
			height:rem(120px);
			.contact{
				width: rem(850px);
				position:absolute;
				left:0;
				right:0;
				margin:auto;
				transform:translateX(312px);
				top:rem(20px);
			}
			nav{
				width: rem(705px); 
				left:0;
				right:0;
				margin:auto;
				transform:translateX(382px);
				top:rem(65px);
				position:absolute;
			}
		}
	}

	// Mobile Contact Data
	//////////////////////////////

	.contact {
		text-align: right; 
		padding: rem(80px) rem(40px) rem(30px); 
		position: relative; 
		background: rgba($primary,.05);

		@include breakpoint(large) {
			padding:0 0 rem(10px) 0; 
			border-bottom:1px solid rgba($light, .3);
			width: 100%;
		}

		> span {
			display: block; 
			font-size: rem(16px); 
			line-height: rem(26px); 
			color: $primary;
			@include breakpoint(large) {
				color: $light;
				display: inline-block; 
			}

			&.head {
				font-weight: 700; 
				color: $dark; 
				margin-bottom: rem(5px); 
				@include breakpoint(large) {
					color: $light;
					display: inline-block; 
					font-weight: 300; 
				}
			}

			a {
				color: $primary; 
				text-decoration: none; 
				transition: color 300ms ease-in-out;
				font-weight: 700;
				@include breakpoint(large) {
					color: $light;
					display: inline-block; 
				}

				&:hover {
					color: $medium;
				}
			}

			
		}
	}
}

.mobile-bar {
	display:block;

	@include breakpoint(large) {
		display: none;
	}

	label[for="navi-toggled"] {
		display:block; 
		color:$light; 
		background:lighten($primary, 15%);
		font-family: $main-font; 
		font-size:rem(18px); 
		line-height: rem(49px); 
		text-align: center; 
		text-decoration:none; 
		width:rem(120px); 
		height: rem(50px); 
		text-transform:uppercase; 
		font-weight:700; 
		margin:auto; 
		position: fixed; 
		top: 0; 
		right: 0; 
		z-index: 110; 
		cursor: pointer; 
		transition:all 300ms ease-in-out;		

		> span {
	    	padding-left: rem(25px); 
	    	position: relative;

	    	&:before {
	    		content: '';
	    		width: rem(16px); 
	    		height: rem(3px); 
	    		background:$light; 
	    		position: absolute; 
	    		top: rem(4px); 
	    		left: 0; 
	    		transition: all 300ms ease-in-out;
	    	}

	    	&:after {
	    		content: ''; 
	    		width: rem(13px); 
	    		height: rem(3px); 
	    		background:$light; 
	    		position: absolute; 
	    		top: rem(14px); 
	    		left: rem(3px); 
	    		transition: all 300ms ease-in-out;
	    	}

	    	> span {
	    		&:before {
					content: ''; 
					width: rem(9px); 
					height: rem(3px); 
					background:$light; 
					position: absolute; 
					top: rem(9px); 
					left: rem(7px); 
					transition: all 300ms ease-in-out;
				}
	    	}
	    }

	    &:hover {
			color:$light; 
			background:$dark;
    	
			> span {
		    	&:before {
		    		width: rem(16px); 
		    		background:$light;
		    	}

		    	&:after {
		    		width: rem(16px); 
		    		left: 0; 
		    		background:$light;
		    	}

		    	> span {
		    		&:before {
						width: rem(16px); 
						left: 0; background:$light;
					}
		    	}
		    }
		}
	}		
}

#navi-toggled {
	display: none;

	&:checked {
		~ * label[for="navi-toggled"] {
			> span {
		    	&:before {
		    		width: rem(16px); 
		    		top: rem(9px); 
		    		left: 0; 
		    		transform: rotate(45deg);
		    	}

		    	&:after {
		    		width: rem(16px); 
		    		top: rem(9px); 
		    		left: 0; 
		    		transform: rotate(-45deg);
		    	}

		    	> span {
		    		&:before {
						opacity: 0
					}
		    	}
		    }
		}

		~ * .page-navi, ~ .page-navi {
			margin-top: 0; 
			transform: translateY(0);
		}
	}
}



// Main Navigation
//////////////////////////////

.navi-main {
	list-style:none; 
	text-align: right; 
	padding: rem(30px) rem(40px) rem(80px) rem(40px);

	@include breakpoint(large) {
		display: flex; 
		justify-content: 
		space-between; 
		width: 100%; 
		text-align: center;
		padding:  rem(15px) 0 0 0;
	}

	li {
		font-size: rem(18px); 
		line-height: rem(24px); 
		font-weight: 300; 
		font-family: $main-font; 
		position: relative; 
		width: 100%; 
		display: block; 
		margin-bottom: rem(25px);
		text-transform:uppercase;
		
	
		@include breakpoint(large) {
			margin-bottom: 0; 
			width: auto; 
			padding: 0;
			font-size: rem(16px); 

			&.anfahrt, 
			&.impressum, 
			&.datenschutzerklaerung {
				display: none;
			}
		}

		a {
			color: $dark; 
			text-decoration: none; 
			position: relative; 
			display: block; 
			transition: all 300ms ease-in-out; 
			@include breakpoint(large) {
				color: $medium; 
			}
		}

		&:hover, &.active {
			a {
				color: lighten($primary, 30%);
				@include breakpoint(large) {
					color: $light; 
				}
			}
		}
	}

	.hasSub {
		font-weight: 700;
		&:before {
			@extend .fa-default;
			@extend .fa-angle-down;
			display:none;
		}

		@include breakpoint(large) {
			font-weight: 300;
			position:relative;
			padding-right:rem(20px);

			&:before{
				display:inline-block;
				position:absolute;
				right:0;
				color:$medium;
				font-size: rem(18px);
				top:rem(3px);
			}

			&:hover {
				.sub {
					opacity: 1; 
					visibility: visible;
				}
			}
		}

		> a {
			border-bottom: $base-border; 
			padding-bottom: rem(10px);

			@include breakpoint(large) {
				border-bottom: none; 
				padding-bottom: 0;
			}

			&:hover, &.active {
				color: $medium;
				@include breakpoint(large) {
					color: $light;
				}
			}
		}
	}

	.sub {
		margin-top: rem(10px);

		@include breakpoint(large) {
			position:absolute; 
			top: 150%; 
			left: 50%; 
			transform: translateX(-50%);
			min-width:rem(200px); 
			text-align: center; 
			opacity: 0; 
			visibility: hidden; 
			transition:all 650ms ease-in-out; 
			background:$primary; 
			margin: auto;

			body.iexplore &{
				transition:none; 
			}
		}

		li {
			font-size: rem(16px); 
			margin-bottom: rem(10px);
			text-transform:uppercase;

			@include breakpoint(large) {
				white-space: nowrap; 
				border-top: 1px solid rgba($light, .3); 
				margin-bottom: 0; 
				padding: 0;

				
			}

			a {
				color: $primary;

				@include breakpoint(large) {
					padding: rem(10px) rem(20px);
					color: $medium;
				}
			}

			&:hover, &.active {
				a {
					color: $medium;
					@include breakpoint(large) {
						color: $light;
					}
				}
			}
		}			
	}
}


/* NAVIADD */

.navi-add { 
	display: none;

	@include breakpoint(large) {
		display:flex;
		justify-content: space-between;
		width:60%;
		margin: 0;
		position:relative;
		z-index:2;
		max-width: rem(472px);
	}
	
	li { 
		display:inline-block;
		position: relative;
		text-transform: uppercase;

		a {
			color:$dark;
			font-size:rem(16px);
		}
		
		&:before {
			content: '';
		    background: $medium;
		    width: 0;
		    height:1px;
		    position: absolute;
		    top: rem(28px);
		    left: 0;
		    transition: all 300ms ease-in-out;
		    display:block;
		}
		

		&:hover,
		&.active {

			&:before {
				width:100%;
			}

		}

	}	
}

