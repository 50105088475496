* {
    margin: 0;
    padding: 0;

    &, &:before, &:after {
        box-sizing: inherit;
    }
}

::selection {}
::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
    background: $secondary;
    box-sizing: border-box;
    color: $dark;
    font-family: $main-font;
    font-size: 100% * ($base-font-size / 16px);
    hyphens: manual;
    line-height: rem($base-line-height);

    /* disable text resize in landscape. e.g. on iphone */
    text-size-adjust: none;
    font-weight: 300;
}

body {
    line-height: rem($base-line-height);

    // Debug mode
    &.the-customer-page #log_hider {
        display: none;
    }

    &.ie-8 #pageloader:after {
        display: none;
    }
}

iframe {
    border:none;
    width: 100%;
}

/**
 * General Margin
 */
p, ol, dl, .margin-bottom {

    margin-bottom: rem(15px);

    @include breakpoint(large) {
        margin-bottom: rem(20px);
    }
    
}
p, ol, ul, dl, span, strong, b, address{
    font-size:rem(16px);
    line-height:rem(24px);

    @include breakpoint(large) {
        font-size:rem(18px);
        line-height:rem(28px);
    }
}

b, strong{
    font-weight:700;
}

/**
 * Headlines
 */

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    text-rendering: optimizeLegibility;
    line-height: 110%;
    margin-bottom: 1rem;
    text-align: left;
    font-family: $main-font;
    color:$dark;
    font-weight:700;

    @include breakpoint(giant) {
        margin-bottom: 2.2rem;
    }

    
    span {
        display: block;
        color:$dark;
        font-family: $display-font;
        font-size: 0.8em;
        font-weight: 300;
        line-height: 1.2em;

        @include breakpoint(large) {
            font-size: 0.7em;
        }
        @include breakpoint(huge) {
           font-size: 0.6em;
        }
    }
}
h1, .h1 {
    font-size: rem($h1-size*.50);
    position: relative;
    padding-bottom:rem(20px);
    border-bottom:1px solid $medium;

    @include breakpoint(small) {
        font-size: rem($h1-size*.65);
    }
    @include breakpoint(large) {
        font-size: rem($h1-size*.8);
    }
    @include breakpoint(giant) {
        font-size: rem($h1-size);
        margin-bottom: 2.5rem;
        padding-bottom:rem(40px);
    }

    span {
        text-transform: none;
    }
}
h2, .h2 {
    font-size: rem($h2-size*.5);
    line-height: 120%;
    @include breakpoint(small) {
        font-size: rem($h2-size*.6);
    }
    @include breakpoint(medium) {
        font-size: rem($h2-size*.75);
    }
    @include breakpoint(giant) {
        font-size: rem($h2-size*.85);
    }
    @include breakpoint(huge) {
        font-size: rem($h2-size);
    }
    span {
        font-size: 1.2em;
            
    }
   
}
h3, .h3 {
    font-size: rem($h3-size*.6);
    line-height: 120%;
    @include breakpoint(small) {
        font-size: rem($h3-size*.8);
    }
    @include breakpoint(medium) {
        font-size: rem($h3-size*.85);
    }
    @include breakpoint(giant) {
        font-size: rem($h3-size*.9);
    }
    @include breakpoint(huge) {
        font-size: rem($h3-size);
    }

    &.small{
        @include breakpoint(medium) {
            font-size: rem($h3-size*.8);
            margin-bottom: rem(20px);
        }
    }
}
h4, .h4 {
    text-align: left;
    font-size: rem($h4-size*.6); 
    @include breakpoint(small) {
        font-size: rem($h4-size*.7);
    }
    @include breakpoint(medium) {
        font-size: rem($h4-size*.75);
    }
    @include breakpoint(giant) {
        font-size: rem($h4-size*.8);
    }
    @include breakpoint(huge) {
        font-size: rem($h4-size);
    }
}
h5, .h5 {
    text-align: left;
    font-size: rem($h5-size*.6); 
    @include breakpoint(small) {
        font-size: rem($h5-size*.7);
    }
    @include breakpoint(medium) {
        font-size: rem($h5-size*.75);
    }
    @include breakpoint(giant) {
        font-size: rem($h5-size*.8);
    }
    @include breakpoint(huge) {
        font-size: rem($h5-size);
    }
}
h6, .h6 {
    text-align: left;
    font-size: rem($h6-size);
}

/**
 * Links
 */
a {
    color: $primary;
    text-decoration: none;

    &:focus, &:hover, &:active {
        color: $secondary;
    }

    img {
        border: none;
    }

    &[href$=".pdf"]{
        margin-bottom:rem(10px);

        &:before {
            @extend .fa-default;
            @extend .fa-file-pdf-o;
            margin-right: 0.5em;

        }

        &.cms{
            margin-bottom:rem(20px);
            display:block;
            @include breakpoint(large) {
                margin-bottom:rem(10px);
            }
        }
    }


    &[href^=tel] {
        color:inherit;
        text-decoration:underline;
        @include breakpoint(large) {
            pointer-events: none;
        }
    }
}

hr {
    background: $medium;
    border: none;
    clear: both;
    height: rem(1px);
    margin: 1rem 0;
    flex-basis:100%;

    @include breakpoint(medium) {
        margin: 2rem 0;
    }
    @include breakpoint(giant) {
        margin: 3rem 0;
    }

    &.invisible{
        background: none;
    }
}

/**
 *UL
 */
ul {
    list-style: none;

    .content & {
        @extend .margin-bottom;

        li {
            padding-top: .3rem;
            padding-left:rem(30px);
            position:relative;

            &:before {
                @extend .fa-check;
                position:absolute;
                color:$primary;
                top:rem(5px);
                left:0;
                font-family:$icon-font;
            }
        }

        ul {
            margin-left: 1rem;
            margin-bottom: 0;
        }
    }

    &.contentList{
        display: flex;
        flex-wrap:wrap; 

        li {
            flex-basis:100%;            

            @include breakpoint(large) {
                flex-basis: 50%;
                body.iexplore &{
                    flex-basis: 45%;    
                }
            }

            @include breakpoint(huge) {
                flex-basis: 33%;
                body.iexplore &{
                    flex-basis: 28%;    
                }
            }
        }
    }

    &.unstyled-list {
        margin-bottom:0;

        li {
            padding-bottom:0;
            padding-top:0;

            &:before {
                content: none;
            }
        }
    }
}




ol {
  list-style-type: none;
  counter-reset: item;

  li {
    padding-top: .3rem;
    padding-bottom: .3rem;
    display: table;
    counter-increment: item;

        &:before {
            content: counters(item, ".") ". ";
            display: table-cell;
            padding-right: 0.3em;
        }
    }

    ol {
        margin-bottom: 0;

        li {

            &:before {
                content: counters(item, ".") " ";
            }
        }
    }
}

// Styled list
dl.floatList {
    @include pie-clearfix;

    dt, dd {
        display: block;
        vertical-align: top;
        float: left;
    }

    dt {
        padding-right: .5rem;
        width: 100%;
        clear:left;
        font-weight:700;

        @include breakpoint(small) {
            width: 40%;
        }
    }

    dd {
        width: 100%;
        margin-bottom: rem(10px);
        @include breakpoint(small) {
            width: 60%;
            margin-bottom: rem(5px);
        }
        &.taxnumber{
            a{
                text-decoration:none;
            }
        }

    }

    &.contact{
        @include breakpoint(small) {
            dt{
                width:20%;
            }
            dd{
                width:80%;
            }
        }
    }
}


/*
 * responsive images
 */

img {
    vertical-align: bottom;
    float:none;
    height:auto;
    max-width:100%;
    width:100%;

    &[src^='http://cms.'] {
        max-width:none;
        width:auto;
    }

    [data-rel] &, .lightbox-image & {
        margin-bottom:0;
    }
}

/*
 * reset copyright link
 */
[href*='alpen-web.ch'],
[href*='bewertet.de'],
[href*='euroweb.at'],
[href*='euroweb.de'],
[href*='ew.de'],
[href*='geprueft.de'],
[href*='web2walk.com'],
[href*='webstyle.de'],
[href*='webstyle.com'],
[href*='wn-onlineservice.de'],
[href*='internet-media.com'],
[href*='yourrate.com'] {
    display:table;
    text-decoration:none;

    img {
        background:none;
        border-radius:0;
        border:none;
        margin-bottom:5px;
        outline:none;
        padding:0;
        white-space:normal;
        width:auto !important;
    }
}

/**
 * Tables
 */
.table-scrollable {
    width: 100%; overflow-y: auto; padding: 0 0 rem(10px); margin-bottom: rem(10px);
}

.table-scrollable::-webkit-scrollbar {
    -webkit-appearance: none; width: 14px; height: 14px;
}

.table-scrollable::-webkit-scrollbar-thumb {
    border-radius: 8px; border: 3px solid #fff; background-color: rgba(0, 0, 0, .3);
}

table {
    width: 100%; border-collapse: collapse; word-break:normal;
}

thead {
    font-weight: 700; 

    th {
        white-space:nowrap; 
    }
}

tbody {
    td {
        text-align: left;
    }
}

th, td {
    padding: rem(8px) rem(13px);
    vertical-align: middle;
    border: $base-border;
    font-size: rem(16px);
    line-height: rem(22px);

    @include breakpoint(large) {
        font-size: rem(18px);
        line-height: rem(28px);
    }
}