// Misc
////////////////////////////////////////////////////////////

.pad{
	padding-right:rem($base-gap);
	padding-left:rem($base-gap);
}

.hint{
	background:rgba($primary, .05);
	margin-bottom:rem(20px);
	padding: 1em .8em ;
	

	&.imprint{
		margin-top:rem(30px);
		padding: 1.3em 1em .3em 1em;
	
		@include breakpoint(small) {
			padding: 1.4em 1.2em .3em 1.2em;
			font-size: 1em;
		}
		p{
			font-size: 0.725em;
			@include breakpoint(large) {
				font-size: 1em;
			}
		}
	}

	&.contact{
		padding:0 1em;
		margin-top:rem(20px);
	}
}
.highlight {
	color:$dark;
	font-weight:700;
	

	&.block{
		display:block;
		margin-bottom:rem(10px);
	}
}

.block{
	display:block;
}

.nojs {
	.email{
		span{
			display:inline;
		}
	}
}

.margin-top,
.imgBox{
	margin-top:rem(20px);

	@include breakpoint(large) {
		margin-top: rem($base-line-height);
	}
	
	&.med{
		@include breakpoint(medium) {
			margin-top:0;
		}
	}

	&.lar{
		@include breakpoint(large) {
			margin-top:0;
		}
	}
	&.gia{
		@include breakpoint(giant) {
			margin-top:0;
		}
	}
}

.margin-bottom{
	margin-bottom:rem(20px);
	
	@include breakpoint(large) {
		margin-bottom: rem($base-line-height);
	}
	
	
	&.med{
		@include breakpoint(medium) {
			margin-bottom:0;
		}
	}

	&.lar{
		@include breakpoint(large) {
			margin-bottom:0;
		}
	}
	&.gia{
		@include breakpoint(giant) {
			margin-bottom:0;
		}
	}
}

.swap{
	order:1;
	&.sma{
		@include breakpoint(small) {
			order:0;
		}
	}
	&.med{
		@include breakpoint(medium) {
			order:0;
		}
	}
	&.lar{
		@include breakpoint(large) {
			order:0;
		}
	}
}

.btn {
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;

	&:hover, &:active, &:focus {
		background: lighten($primary, 15%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}


// HEADER
////////////////////////////////////////////////////////////

.header {

	margin-bottom:rem(50px);
	@include breakpoint(medium) {
		margin-bottom:rem(80px);
	}
	
	@include breakpoint(giant) {
		margin-bottom:rem(100px);
	}
	@include breakpoint(huge) {
		margin-bottom:rem(120px);
	}

	.top {
		display: block;
		background:$primary;
		padding:rem(65px) 0 rem(30px) 0;

		@include breakpoint(tiny) {
			padding:rem(30px) 0;
		}
		@include breakpoint(large) {
			padding:rem(40px) 0;
		}
		@include breakpoint(huge) {
			padding:rem(50px) 0;
		}

		
	}



	// bottom
	//////////////////////////////

	.bottom {
		display: block;
		width:100%;

	}

	.slider-m {
		width:100%;	height:rem(300px); background:image-url("layout/slider/ansicht-muenchen-small.jpg") center center no-repeat;
		background-size:cover; position: relative;

		@include breakpoint(small) {
			background:image-url("layout/slider/ansicht-muenchen-large.jpg") center center no-repeat;
			background-size:cover;
		}
		@include breakpoint(large) {
			background:image-url("layout/slider/ansicht-muenchen.jpg") center center no-repeat;
			background-size:cover;
		}
			
		&:before{
			@include gradient;

			@include breakpoint(large) {
				height:60%;
				
			}
		}

		.row {
			height: 100%;
		}

		.claim {
			bottom: rem(30px);

			@include breakpoint(huge) {
				bottom: rem(60px);
			}
		}
	}

	.slider-b {
		width:100%;	height:rem(300px); background:image-url("layout/slider/ansicht-muenchen-small.jpg") center center no-repeat;
		background-size:cover; position: relative;

		@include breakpoint(small) {
			background:image-url("layout/slider/ansicht-muenchen-large.jpg") center center no-repeat;
			background-size:cover;
		}
		@include breakpoint(large) {
			background:image-url("layout/slider/ansicht-muenchen.jpg") center center no-repeat;
			background-size:cover;
		}
			
		&:before{
			@include gradient;

			@include breakpoint(large) {
				height:60%;
				
			}
		}

		.row {
			height: 100%;
		}

		.claim {
			bottom: rem(30px);

			@include breakpoint(huge) {
				bottom: rem(60px);
			}
		}
	}

	.slider-k {
		width:100%;	height:rem(300px); background:image-url("layout/slider/ansicht-muenchen-small.jpg") center center no-repeat;
		background-size:cover; position: relative;

		@include breakpoint(small) {
			background:image-url("layout/slider/ansicht-muenchen-large.jpg") center center no-repeat;
			background-size:cover;
		}
		@include breakpoint(large) {
			background:image-url("layout/slider/ansicht-muenchen.jpg") center center no-repeat;
			background-size:cover;
		}
			
		&:before{
			@include gradient;

			@include breakpoint(large) {
				height:60%;
				
			}
		}

		.row {
			height: 100%;
		}

		.claim {
			bottom: rem(30px);

			@include breakpoint(huge) {
				bottom: rem(60px);
			}
		}
	}

	.slider{
		width:100%;	

		.row {
			max-width: none;
		}
		.slide {
			height:rem(300px);
			width:100%;
			@include breakpoint(small) {
				.index &{
					height:rem(350px);
				}
			}

			@include breakpoint(large) {

				.index &{
					height:rem(400px);
				}
			}

			@include breakpoint(giant) {
				.index &{
					height:rem(500px);
				}
			}

			@include breakpoint(huge) {
				.index &{
					height:rem(610px);
				}
			}
			
			&:before{
				@include gradient;

				@include breakpoint(large) {
					height:60%;
					
				}
			}
			
			.javascript &{

				&.slide-1{
					background:image-url("layout/slider/ansicht-muenchen-small.jpg") center center no-repeat;
					background-size:cover;

					@include breakpoint(small) {
						background:image-url("layout/slider/ansicht-muenchen-large.jpg") center center no-repeat;
						background-size:cover;
					}
					@include breakpoint(large) {
						background:image-url("layout/slider/ansicht-muenchen.jpg") center center no-repeat;
						background-size:cover;
					}

				}
				&.slide-2{
					background:image-url("layout/slider/ansicht-muenchen-small.jpg") center center no-repeat;
					background-size:cover;
					@include breakpoint(small) {
						background:image-url("layout/slider/ansicht-muenchen-large.jpg") center center no-repeat;
						background-size:cover;
					}
					@include breakpoint(large) {
						background:image-url("layout/slider/ansicht-muenchen.jpg") center center no-repeat;
						background-size:cover;
					}
				}
				&.slide-3{
					background:image-url("layout/slider/ansicht-muenchen-small.jpg") center center no-repeat;
					background-size:cover;
					@include breakpoint(small) {
						background:image-url("layout/slider/ansicht-muenchen-large.jpg") center center no-repeat;
						background-size:cover;
					}
					@include breakpoint(large) {
						background:image-url("layout/slider/ansicht-muenchen.jpg") center center no-repeat;
						background-size:cover;
					}
				}
			}

			.nojs &{
				display:none;
				&.slide-1{
					background:image-url("layout/slider/ansicht-muenchen-small.jpg") center center no-repeat;
					background-size:cover;
					display:block;

					@include breakpoint(small) {
						background:image-url("layout/slider/ansicht-muenchen-large.jpg") center center no-repeat;
						background-size:cover;
					}
					@include breakpoint(large) {
						background:image-url("layout/slider/ansicht-muenchen.jpg") center center no-repeat;
						background-size:cover;
					}
				}
			}
		}
	}

	.claim{
		color:$light;
		position:absolute;
		bottom:rem(55px);
		left:rem($base-gap);

		@include breakpoint(small) {
			bottom:rem(40px);
		}

		@include breakpoint(giant) {
			bottom:rem(80px);
		}

		@include breakpoint(huge) {
			width:rem(485px);
			left:0;
			right:0;
			margin:auto;
			transform:translateX(-495px);
			.index &{
				width:rem(735px);
				left:0;
				right:0;
				margin:auto;
				transform:translateX(-368px);
			}
		}

		span{
			display:block;
			text-transform: uppercase;
			letter-spacing: 4px;
			@include breakpoint(small) {
				font-size: rem(18px);
			}
			@include breakpoint(large) {
				.index &{
					font-size: rem(20px);
					margin-bottom: rem(5px);
				}
			}
		}
		strong{
			display:block;
			font-size:rem(25px);
			line-height: rem(25px);

			@include breakpoint(medium) {
				font-size:rem(25px);
				line-height: rem(25px);
				.index &{
					font-size:rem(30px);
					line-height: rem(30px);
				}
			}
			@include breakpoint(giant) {
				font-size:rem(30px);
				line-height: rem(30px);
				.index &{
					font-size:rem(40px);
					line-height: rem(40px);
				}
			}
			@include breakpoint(huge) {
				.index &{
					font-size:rem(46px);
					line-height: rem(46px);
				}
			}


			span{
				font-size:0.8em;
				line-height: 1.6em;
				text-transform: none;
				letter-spacing: 0;
				margin-bottom: 0;
				.index &{
				font-size:0.8em;
				line-height: 1.6em;	
				margin-bottom: 0;
				}
			}
		}
	}
}

.branding {
	max-width: rem(200px); 
	width: 100%; 
	margin: 0 auto; 
	display: block;
	color:$light;
	text-align:right;
	text-transform:uppercase;
	color:$medium;
	@include transition;

	@include breakpoint(large) {
		margin: 0; 
	
	}

	@include breakpoint(huge) {
		font-size: rem(20px);
	}
	span{
		font-size: 3em;
		line-height: 1em;
		display:block;
		color:$light;
		@include transition;
		
	}

	&:hover{
		color:$light;
		
	}
}





//CONTENT
////////////////////////////////////////////////////////////

.content {
	display: block; 
	margin-bottom:rem(50px);
	@include breakpoint(medium) {
		margin-bottom:rem(80px);
	}
	@include breakpoint(huge) {
		margin-bottom:rem(100px);
	}
	


	a{
		color:darken($primary, 15%);
		font-weight: 700;
		text-decoration: none;
		@include transition;
		&:hover{
			color:$medium;
		}
	}

	.google-maps {
		iframe {
			border: none; 
			width: 100%; 
			height: rem(300px);
			@include breakpoint(medium) {
				height: rem(480px);
			}

			@include breakpoint(large) {
				height: rem(466px);
			}

			@include breakpoint(huge) {
				height: rem(410px);
			}
		}
	}

	.box {
		@include pie-clearfix; background:$light; padding: rem(20px); margin-bottom: rem(15px);

		@include breakpoint(small) {
			padding: rem(25px); display: flex; justify-content: center; align-items: center;
		}

		@include breakpoint(large) {
			margin-bottom: rem(36px);
		}

		@include breakpoint(giant) {
			padding: rem(30px);
		}

		@include breakpoint(huge) {
			padding: rem(35px);
		}

		img {
			width: rem(150px); display: block; margin-bottom: rem(15px);

			@include breakpoint(small) {
				margin-bottom: 0; width: 40%;
			}

			@include breakpoint(huge) {
				width: 45%;
			}
		}

		.text {
			@include breakpoint(small) {
				padding-left: rem(25px);
			}

			@include breakpoint(giant) {
				padding-left: rem(30px);
			}

			@include breakpoint(huge) {
				padding-left: rem(35px);
			}
		}

		h3 {
			font-size: rem(16px); line-height: rem(22px); margin-bottom: rem(5px);

			@include breakpoint(giant) {
				font-size: rem(18px);
			}

			@include breakpoint(huge) {
				font-size: rem(20px); margin-bottom: rem(10px);
			}
		}

		p {
			margin-bottom: rem(10px); font-size: rem(16px); line-height: rem(22px);

			@include breakpoint(huge) {
				font-size: rem(18px); line-height: rem(28px); margin-bottom: rem(15px);
			}
		}

		a {
			font-size: rem(16px); line-height: rem(22px); display: block; text-align: right; font-weight: 300; text-transform: uppercase; letter-spacing: .1em;

			@include breakpoint(huge) {
				font-size: rem(18px); line-height: rem(24px);
			}
		}
	}
}




// FOOTER
////////////////////////////////////////////////////////////

.footer {
	
	// Teaser
	///////////////////////////////////

	.teaserBox{
		background:$light;
		padding:rem(50px) 0;

		@include breakpoint(giant) {
			padding:rem(70px) 0;
		}
		.row{
			flex-direction: column;

			@include breakpoint(medium) {
				flex-direction: row;
				align-items:flex-end;
			}
		}

		.head{
			display:block;
			font-weight: 700;
			font-size: rem(22px);
			line-height:rem(32px);
			margin-bottom: rem(30px);
			@include breakpoint(small) {
				font-size: rem(25px);
				line-height:rem(38px);
			}

			@include breakpoint(medium) {
				margin-bottom: 0;
				font-size: rem(28px);
				line-height:rem(40px);
			}
			@include breakpoint(large) {
				font-size: rem(35px);
				line-height:rem(48px);
			}
			@include breakpoint(giant) {
				font-size: rem(40px);
				line-height:rem(53px);
			}
			@include breakpoint(huge) {
				font-size: rem(46px);
				line-height:rem(60px);
			}
			span{
				font-weight:300;
				display:block;
				font-size:0.8em;
				line-height:1em;
				@include breakpoint(large) {
					font-size:0.7em;
				}
				@include breakpoint(huge) {
					font-size:0.6em;
				}
			}
		}
		.left{
			@include breakpoint(medium) {
				flex-basis: 35%;
			}

			@include breakpoint(giant) {
				flex-basis: 40%;
			}
		}

		.right{
			display:flex;
			justify-content: space-between;
			@include breakpoint(medium) {
				flex-basis: 65%;
			}
			@include breakpoint(giant) {
				flex-basis: 50%;
			}
		}

		a{
			text-transform: uppercase;
			letter-spacing: 4px;
			font-size: rem(13px);
			line-height: 1em;
			padding-top:rem(70px);
			position:relative;
			flex-basis:30%;
			text-align:center;
			@include transition;

			@include breakpoint(small) {
				font-size: rem(16px);
				padding-top:rem(80px);
			}
			@include breakpoint(large) {
				padding-top:rem(120px);
				font-size: rem(18px);
			}
			@include breakpoint(huge) {
				padding-top:rem(145px);
				font-size: rem(20px);
			}

			&:hover {
				color:$medium;

				&:before{
					opacity:.6;
				}
			}
			&:before{
				position:absolute;
				top:0;
				right:50%;
				transform:translateX(50%);
				content:'';
				@include transition;
			}

			&:nth-of-type(1){
				&:before{
					background:image-url("layout/muenchen-icon.png") center center no-repeat;
					background-size:cover;
					width:rem(50px);
					height:rem(50px);

					@include breakpoint(small) {
						width:rem(60px);
						height:rem(60px);
					}
					@include breakpoint(large) {
						width:rem(90px);
						height:rem(100px);
					}
					@include breakpoint(huge) {
						width:rem(110px);
						height:rem(121px);
					}
				}
			}
			&:nth-of-type(2){
				&:before{
					background:image-url("layout/bremen-icon.png") center center no-repeat;
					background-size:cover;
					width:rem(50px);
					height:rem(60px);
					top:rem(-4px);

					@include breakpoint(small) {
						width:rem(60px);
						height:rem(70px);
					}
					@include breakpoint(large) {
						width:rem(90px);
						height:rem(110px);
					}
					@include breakpoint(huge) {
						width:rem(100px);
						height:rem(121px);
					}
				}
			}
			&:nth-of-type(3){
				&:before{
					background:image-url("layout/koeln-icon.png") center center no-repeat;
					background-size:cover;
					width:rem(80px);
					height:rem(50px);
					@include breakpoint(small) {
						width:rem(90px);
						height:rem(60px);
					}
					@include breakpoint(large) {
						width:rem(130px);
						height:rem(100px);
					}
					@include breakpoint(huge) {
						width:rem(145px);
						height:rem(120px);
					}
				}
			}
		}
	}
	

	// ContactBOX
	//////////////////////////////

	.contactBox {
		display: block;
		background:$primary;
		color:$light;
		padding:rem(40px) 0;

		@include breakpoint(large) {
			padding:rem(50px) 0;
		}
		@include breakpoint(huge) {
			padding:rem(60px) 0;
		}

		.row{
			flex-direction: column;
			align-items:center;
			@include breakpoint(medium) {
				flex-direction:row;
				flex-wrap:wrap;
				justify-content: space-between;
			}
			@include breakpoint(large) {
				align-items:center;
			}
		}
		.branding{
			font-size:rem(14px);
			max-width: rem(170px); 
			margin-bottom:rem(20px);
			@include breakpoint(large) {
				margin-bottom:0;
			}
		
			@include breakpoint(huge) {
				max-width: rem(190px); 
				font-size:rem(16px)
			}
		}

		span{
			display:block;
			
		}
		a{
			text-decoration:none;
		}

		
		.logo{
			@include breakpoint(medium) {
				flex-basis: 100%;
				width:100%;
			}
			@include breakpoint(large) {
				flex-basis: 22%;
				width:auto;
			}
		}


		.info,
		.openingHours{
			width:rem(230px);
			@include breakpoint(small) {
				width:rem(288px);
			}
			@include breakpoint(medium) {
				width:auto;
				flex-basis:33%;
			}
			@include breakpoint(large) {
				flex-basis:23%;
			}

			span,
			b{
				@include breakpoint(medium) {
					font-size: rem(14px);
				}

				@include breakpoint(huge) {
					font-size: rem(16px);
				}
				
			}

		}

		.info{
			margin-bottom:rem(20px);
			@include breakpoint(medium) {
				margin-bottom:0;
			}

			&:nth-of-type(2){
				@include breakpoint(medium) {
					flex-basis:37%;
				}
				@include breakpoint(large) {
					flex-basis:27%;
				}
				@include breakpoint(giant) {
					flex-basis:26%;
				}
			}
			b{
				min-width:rem(60px);
				display:inline-block;
			}

			
		}
		.openingHours{

			@include breakpoint(medium) {
				width:rem(240px);
				flex-basis:30%;
			}
			@include breakpoint(large) {
				flex-basis:22%;
			}
			b{
				display:block;
			}
			span{
				span{
					display:inline-block;
					min-width: rem(65px);
					@include breakpoint(medium) {
						min-width: rem(85px);
					}
				}
			}
		}
	}

	// bottom
	//////////////////////////////

	.bottom{
		display:none;
		@include breakpoint(large) {
			display:block;
			background: $light;
			padding:rem(40px) 0; 
		}
		@include breakpoint(huge) {
			padding:rem(50px) 0;
		}
	}


// FOOTER END
//////////////////////////////

}



