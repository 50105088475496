// Icons

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 300;
  src: local('FontAwesome'), local('FontAwesome'),
       url('fonts/fontawesome-webfont.woff') format('woff'), /* Modern Browsers */
}

/* muli-300 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  src: local('Muli Light'), local('Muli-Light'),
       url('fonts/muli-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/muli-v11-latin-300.woff') format('woff'), /* Modern Browsers */
}
/* muli-700 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: local('Muli Bold'), local('Muli-Bold'),
       url('fonts/muli-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/muli-v11-latin-700.woff') format('woff'), /* Modern Browsers */
}